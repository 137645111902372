import EventBus from '@/event-bus'
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import axios from 'axios'

export default {
  mixins: [titleMixins],
  data () {
    return {
      typeOfAccount: 'liability',
      nextDisabled: false,
      finalModel: {},
      isUpdate: false,
      translationPath: 'assets_liabilities.create_account_wizard.common.',
      editAccountDetails: {},
      iAccountId: null,
      editPathName: 'liability-accounts-edit'
    }
  },
  created () {
    EventBus.$on('group_selected', status => {
      this.nextDisabled = !status
    })
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    titleMeta () {
      return this.isUpdate ? this.$t(this.translationPath + 'edit_' + this.typeOfAccount) : this.$t(this.translationPath + 'create_' + this.typeOfAccount)
    }
  },
  methods: {
    getFormattedDate (iDate) {
      const oDate = new Date(iDate)
      let sMonth = oDate.getMonth() + 1
      if (sMonth < 10) {
        sMonth = '0' + sMonth
      }
      let sDay = oDate.getDate()
      if (sDay < 10) {
        sDay = '0' + sDay
      }
      return oDate.getFullYear() + '-' + sMonth + '-' + sDay
    },
    loadAccountDetails () {
      const accountId = (Object.keys(this.$route.params).length > 0) ? (Object.prototype.hasOwnProperty.call(this.$route.params, 'account_id') ? this.$route.params.account_id : null) : null
      this.getAccountDetails(accountId)
        .then(responseData => responseData.data.data)
        .then(data => {
          this.editAccountDetails = data
          this.iAccountId = data.id
          Object.assign(this.finalModel, { account_title: data.title })
        })
        .catch(err => {
          console.error(err)
        })
    },
    mergePartialModels (model) {
      this.finalModel = Object.assign({}, this.finalModel, model)
    },
    async getAccountDetails (accountId) {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/${accountId}`)
    },
    validateStep (name) {
      const selectedAccountGroup = this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'selected_account') && this.finalModel.selected_account && Object.prototype.hasOwnProperty.call(this.finalModel.selected_account, 'id') ? this.finalModel.selected_account.id : null
      if (!this.isUpdate && name === 'step2' && selectedAccountGroup) {
        this.$refs.step3.setIdentityCode(selectedAccountGroup)
      }
      return this.$refs[name].validate()
    }
  },
  watch: {
    finalModel () {
      this.h1AccountTitle = ': "' + this.finalModel.account_title + '"'
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    isUpdate: function (value) {
      if (value) this.loadAccountDetails()
    },
    $route: {
      handler: function (value) {
        if (value && value.name === this.editPathName) this.isUpdate = true
      },
      immediate: true,
      deep: true
    }
  }
}
