<template>
  <b-container class="account-wizard bg-light page-wrapper">
    <b-row class="main-content-wrapper create-liability-wrapper" no-gutters>
      <b-col>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        <h1 class="text-left mb-0" v-if="!isUpdate">{{ $t( translationPath + 'create_liability') }}{{ h1AccountTitle }}</h1>
        <h1 class="text-left mb-0" v-if="isUpdate">{{ $t( translationPath + 'edit_liability') }}{{ h1AccountTitle }}</h1>
        <div class="step-container-v2">
          <div class="stepper bg-light mx-auto text-regular">

            <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="step">
              <tab-content :title="$t(translationPath+ 'general_info')" :before-change="()=>validateStep('step1')">
                <liability-account-wizard-step01 ref="step1"  @on-validate="mergePartialModels" :initialData="editAccountDetails" :typeOfAccount="typeOfAccount" :isUpdate="isUpdate"></liability-account-wizard-step01>
              </tab-content>
              <tab-content :title="$t(translationPath+ 'liability_group')" :before-change="()=>validateStep('step2')">
                <liability-account-wizard-step02 ref="step2" @on-validate="mergePartialModels" :initialData="editAccountDetails" :typeOfAccount="typeOfAccount" :isUpdate="isUpdate"></liability-account-wizard-step02>
              </tab-content>
              <tab-content :title="$t(translationPath+ 'advanced_options')" :before-change="()=>validateStep('step3')">
                <liability-account-wizard-step03 ref="step3" @on-validate="mergePartialModels" :initialData="editAccountDetails" :typeOfAccount="typeOfAccount" :finalModel="finalModel" v-bind:totalRows="5" :isUpdate="isUpdate"></liability-account-wizard-step03>
              </tab-content>
              <template slot="footer" slot-scope="props">
                <div :class="{ hasSpace: props.activeTabIndex !== 0 }">
                  <div class=wizard-footer-left>
                    <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t('common.previous') }}
                    </wizard-button>
                  </div>
                  <div class="line" v-if="props.activeTabIndex !== 0"></div>
                  <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="props.activeTabIndex === 1 && nextDisabled" tabindex="0">
                      {{ $t('common.next') }}
                    </wizard-button>
                    <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t('common.save') }}
                    </wizard-button>
                  </div>
                </div>
              </template>
            </form-wizard>

            <success-modal v-if="!isUpdate"
              :title="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'title_account' : 'title_category'),{account: this.finalModel.account_title})"
              :message="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'message_account' : 'message_category'),{account: this.finalModel.account_title})"
              :button="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'button_account_overview' : 'button_category_overview'),{account: this.finalModel.account_title})"
              :redirect="'/reports/other/account-plan-v2/' + (typeOfAccount === 'asset' ? 'assets' : 'liabilities')"
              :orLink="'/redirect?to=accountsAssetsCreate&accountType='+typeOfAccount"
              :orLinkText="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'or_create_account' : 'or_create_category'))"
              @hide="$router.push(`/redirect?to=accountsAssetsCreate&accountType=${typeOfAccount}`)"
            ></success-modal>
            <success-modal v-if="isUpdate"
              :title="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'title_account_update' : 'title_category_update'),{account: this.finalModel.account_title})"
              :message="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'message_account_update' : 'message_category_update'),{account: this.finalModel.account_title})"
              :button="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'button_account_overview_update' : 'button_category_overview_update'),{account: this.finalModel.account_title})"
              :redirect="'/reports/performance/accounts/' + iAccountId + '/view'"
              :orLink="'/reports/other/account-plan-v2/' + (typeOfAccount === 'asset' ? 'assets' : 'liabilities')"
              :orLinkText="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'or_goto_account_overview' : 'or_goto_category_overview'))"
              @hide="$router.push({ name: 'account-performance', params: { account_id: iAccountId, tab: 'twelve-months-rolling'} })"
            ></success-modal>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LiabilityAccountWizardStep01 from './wizard-steps/liability/LiabilityAccountWizardStep01'
import LiabilityAccountWizardStep02 from './wizard-steps/liability/LiabilityAccountWizardStep02'
import LiabilityAccountWizardStep03 from './wizard-steps/liability/LiabilityAccountWizardStep03'
import SuccessModal from '@/components/modals/SuccessModal'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import axios from 'axios'
import AssetsLiabilityAccountsWizardMixins from '@/mixins/AssetsLiabilityAccountsWizardMixins'

export default {
  name: 'wizardAccountLiability',
  mixins: [AssetsLiabilityAccountsWizardMixins],
  data () {
    return {
      isCompleted: false,
      h1AccountTitle: null,
      translationPathModal: 'assets_liabilities.create_account_wizard.liabilities.success_modal.',
      isStep3Ok: null,
      typeOfAccount: 'liability',
      editPathName: 'liability-accounts-edit'
    }
  },
  components: {
    SuccessModal,
    FormWizard,
    TabContent,
    WizardButton,
    LiabilityAccountWizardStep01,
    LiabilityAccountWizardStep02,
    LiabilityAccountWizardStep03
  },
  computed: {
    breadcrumbItems () {
      const aBreadCrumb = [
        { text: this.$t('common.header.header_menu.manage_title'), to: '/reports/other/account-plan-v2/liabilities' },
        { text: this.$t(this.translationPath + 'liability'), to: '/reports/other/account-plan-v2/liabilities' },
        { text: '', active: true }
      ]
      return aBreadCrumb
    }
  },
  methods: {
    async onComplete () {
      var self = this

      await this.validateStep('step3')
        .then(function (response) {
          self.isStep3Ok = response
        })
        .catch(function (error) { console.error(error) })

      if (self.isStep3Ok) {
        const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/accounts' + (this.isUpdate ? '/' + this.$route.params.account_id : '')
        const sApiMethod = this.isUpdate ? 'put' : 'post'
        await axios({
          method: sApiMethod,
          url: sApiEndpoint,
          data: this.getWizardData()
        })
          .then(function (response) {
            self.$bvModal.show('successModal')
            const startingValue = !self.isUpdate && self.finalModel && Object.prototype.hasOwnProperty.call(self.finalModel, 'starting_value') && self.finalModel.starting_value ? self.finalModel.starting_value : null
            const networthAccountId = self.currentCOA && Object.prototype.hasOwnProperty.call(self.currentCOA, 'networth_account_id') && self.currentCOA.networth_account_id ? self.currentCOA.networth_account_id : null
            if (startingValue && networthAccountId) {
              const { amount, date } = startingValue
              if (amount && date) {
                const { id, type } = response.data.data
                const postData = {
                  transactions: [
                    {
                      title: 'Initial amount',
                      date: date ? self.getFormattedDate(date) : date,
                      type: 'transfer',
                      description: null,
                      entries: [
                        {
                          account_id: id,
                          dctype: type === 'asset' ? 'dr' : 'cr',
                          amount: Math.abs(amount)
                        },
                        {
                          account_id: networthAccountId,
                          dctype: type === 'asset' ? 'cr' : 'dr',
                          amount: Math.abs(amount)
                        }
                      ]
                    }
                  ]
                }
                const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/transactions/with/entries'
                axios({
                  method: 'post',
                  url: sApiEndpoint,
                  data: postData
                }).catch(err => {
                  console.error(err)
                })
              }
            }
            self.$refs.step.reset()
            if (self.$route.name === 'liability-accounts-edit') {
              self.loadAccountDetails()
            }
          })
          .catch(function (error) {
            console.error(error)
          })
      }
    },
    getWizardData () {
      return {
        group_id: Object.prototype.hasOwnProperty.call(this.finalModel, 'selected_account') && Object.prototype.hasOwnProperty.call(this.finalModel.selected_account, 'id') && this.finalModel.selected_account.id ? this.finalModel.selected_account.id : null,
        title: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_title') && this.finalModel.account_title ? this.finalModel.account_title : null,
        type: Object.prototype.hasOwnProperty.call(this.finalModel, 'selected_account') && Object.prototype.hasOwnProperty.call(this.finalModel.selected_account, 'type') && this.finalModel.selected_account.type ? this.finalModel.selected_account.type : null,
        status: Object.prototype.hasOwnProperty.call(this.finalModel, 'status') && this.finalModel.status ? this.finalModel.status : null,
        description: Object.prototype.hasOwnProperty.call(this.finalModel, 'description') ? this.finalModel.description : null,
        default_active_percentage: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_percentage') ? this.finalModel.account_percentage : null,
        code: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_code') ? this.finalModel.account_code : null,
        is_importable: Object.prototype.hasOwnProperty.call(this.finalModel, 'is_importable') && this.finalModel.is_importable ? this.finalModel.is_importable : 0,
        importable_type: Object.prototype.hasOwnProperty.call(this.finalModel, 'is_importable_value') && this.finalModel.is_importable_value ? this.finalModel.is_importable_value : null,
        importable_default_parser: Object.prototype.hasOwnProperty.call(this.finalModel, 'importable_default_parser') && this.finalModel.importable_default_parser ? this.finalModel.importable_default_parser : null,
        in_bucket: Object.prototype.hasOwnProperty.call(this.finalModel, 'in_bucket') && this.finalModel.in_bucket ? this.finalModel.in_bucket : null,
        global_id: Object.prototype.hasOwnProperty.call(this.finalModel, 'global_id') && this.finalModel.global_id ? this.finalModel.global_id : null,
        is_bucket_free_capital: Object.prototype.hasOwnProperty.call(this.finalModel, 'is_bucket_free_capital') && this.finalModel.is_bucket_free_capital !== null ? this.finalModel.is_bucket_free_capital : 1,
        allow_update: Object.prototype.hasOwnProperty.call(this.finalModel, 'allow_update') && this.finalModel.allow_update ? this.finalModel.allow_update : 0,
        subtype: Object.prototype.hasOwnProperty.call(this.finalModel, 'subtype') && this.finalModel.subtype ? this.finalModel.subtype : null,
        tink_input_provider: Object.prototype.hasOwnProperty.call(this.finalModel, 'tink_input_provider') && this.finalModel.tink_input_provider ? this.finalModel.tink_input_provider : null,
        tink_id: Object.prototype.hasOwnProperty.call(this.finalModel, 'tink_id') && this.finalModel.tink_id ? this.finalModel.tink_id : null,
        tink_auto_import: Object.prototype.hasOwnProperty.call(this.finalModel, 'tink_auto_import') && this.finalModel.tink_auto_import ? this.finalModel.tink_auto_import : 0
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
@import '@/assets/scss/modal.scss';
.create-liability-wrapper {
  .accounts_wrapper {
    border-left: 1px solid #D7D7D7;
    @media screen and (max-width:767.98px) {
      border-left:none;
    }
  }
}
</style>
